@font-face {
  font-family: Roboto-Black;

  src: url(../fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: Roboto-Light;

  src: url(../fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Bold;

  src: url(../fonts/Roboto-Bold.ttf);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: white;
  border-color: green;
}

/* div.custom-control-right .custom-control-label::before,
div.custom-control-right .custom-control-label::after{
  right: -8.5rem;
  left: initial;
} */

.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='green' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

div.custom-control-right {
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
}
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(58, 58, 58, 0.8); 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(58, 58, 58, 0.8); 
}

/* Hover */
::-webkit-scrollbar-thumb:hover {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(129, 129, 129, 0.8); 
  -webkit-box-shadow: inset 0 0 6px rgba(184, 183, 183, 0.5); 
  box-shadow: inset 0 0 6px rgba(173, 173, 173, 0.3);
}

.container-margin {
  margin-left: -15px;
  margin-right: -15px;
}

.dropdown-toggle::after {
  margin-top: 10px;
  float:right;
}

.square {
  position: relative;
  width: 50%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.scrollView {
  height: calc(100vh - 215px);
}

.order {
  height: calc(100vh - 520px);
  overflow-y: auto;
}

.txt{
  font-family: 'Roboto-Bold';
  position: absolute;
  bottom: 45px;
  right: 20px;
}

.btn-add-order{
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.summary {
  box-shadow: 0 0 3px 3px rgb(0 0 0 / 0.2);
  height: calc(100vh - 400px);
  overflow-x: hidden;
  position: relative;
}

hr.solid {
  margin-left: 15px;
  margin-right: 15px;
  border-top: 1px solid #bbb;
}

.additions {
  font-family: Roboto-Regular;
  font-size: 14px;
  color:rgba(88, 88, 88, 0.8)
}

.main-order{
  font-family: Roboto-Light;
}

.custom-modal{
  max-width: 90% !important;
}

body{
    padding-right: 0!important;
    overflow: hidden;
}

.btn-minus:hover ~ .btn-addition {
  pointer-events: none;
  background-color: red;
}