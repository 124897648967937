.topbar {
  height: 3rem !important;
}
.Font15px {
  font-size: 13px !important;
}
.sidebar.toggled {
  width: 4.5rem !important;
}
.nav-link {
  width: 100% !important;
}
.shadow {
  box-shadow: 10px 16px 48px rgba(0, 0, 0, 0.176) !important;
}
.ColorIcon {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}
@font-face {
  font-family: "flexmenu_icon";
  src: url("./font/flexmenu_icon.eot?33364537");
  src: url("./font/flexmenu_icon.eot?33364537#iefix")
      format("embedded-opentype"),
    url("./font/flexmenu_icon.woff?33364537") format("woff"),
    url("./font/flexmenu_icon.ttf?33364537") format("truetype"),
    url("./font/flexmenu_icon.svg?33364537#flexmenu_icon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.demo-icon {
  font-family: "flexmenu_icon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
