.minHeight {
  min-height: 100% !important;
  height: 100% !important;
}
.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.custom-modal{
  max-width: 90%!important;
}
.Font15px{
  font-size: 12px;
}
.Font12px{
  font-size: 12px;
}
.Font10px{
  font-size: 10px;
}
.w-33{
  width: 33%!important;
  max-width: 33%!important;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    width: 300vw;
  }
}
@media screen and (max-width: 570px) {
  .wrapper {
    display: flex;
    flex-direction: row;
    width: 300vw;
  }
}
